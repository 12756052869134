<template>
    <div class="policybox">
        <div class="">
            <Row type="flex" justify="space-around" class="code-row-bg">
                <Col span="6" push="10" class-name="zixing" @click.native="shouye()" >首页</Col >
                <Col span="6" push="1" class-name="zi">>搜索结果</Col>
            </Row>
        </div>
        <div class="jigoubox">
            <div class="hengxian"></div>
            <div class="contentr" ref="contentr" v-if="!noSearch">
                <div class="" v-for="(item, index) in contentr" :key="index">
                    <div class="tit" v-html="brightenKeyword(item.newsTitle, message)" @click="leix( item.newsMode,item.id)" > {{ item.newsTitle }} </div>
                    <div  class="con"  ref="con" v-html="brightenKeyword(item.content, message)" :style="{ display: item.newsMode == 2 ? 'none' : 'block',}"  ></div>
                    <div class="xiaozi">
                        <div>来源：{{ item.newsResource }}</div>
                        <div>日期：{{ item.createTime }}</div>
                    </div>
                    <div class="hengxian"></div>
                </div>
                <!-- 分页 -->
                <el-pagination
                    class="fenye"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[15]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount"
                >
                </el-pagination>
            </div>
            <div ref="wupipei" v-else>
                <h1 class="baoqian">很抱歉！无搜索结果</h1>
            </div>
        </div>
    </div>
</template>
<script>
import {incrReadingNum, searchMessage } from "../../woke/xinx.js";
import { formatDate } from "../../commm/time";
export default {
    data() {
        return {
            value: "",
            message: "",
            contentr: [],
            noSearch: false,
            totalCount: null, //总数据
            currentPage: 1,
            pageSize:1,
        };
    },
    created() {
        
         if (this.$route.query.message=="") {
             this.$Message.warning('请先输入内容'); 
        }else{
            this.message = this.$route.query.message;
            this.currentPage = this.$route.query.currentPage;
            this.pageSize = this.$route.query.pageSize;
            this.searchMessage(this.message, this.currentPage, this.pageSize);
        }
        //  console.log(this.$route);
        this.$bus.$on("emitSearch", (data) => {
            // console.log(data);
            this.message = data.message;
            this.currentPage = data.currentPage;
            this.pageSize = data.pageSize;
            this.searchMessage(this.message, this.currentPage, this.pageSize);
        });
    },
    activated() {
        
       
       
    },
    methods: {
        //改变分页的每页的页数
        handleSizeChange(size) {
            this.pageSize = size;
            this.searchMessage(this.message, this.currentPage, this.pageSize);
               
        },
        // 改变分页的当前页面
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.searchMessage(this.message, this.currentPage, this.pageSize);
        },
        shouye() {
            this.$router.push("/");
        },
        searchMessage( message,currentPage,pageSize){
            searchMessage({
                message: this.message,
                curPage: this.currentPage,
                maxPage: this.pageSize,
            }).then((res) => {
                if(res.data.code==200){
                    console.log(res, "全局搜索");
                    this.contentr = res.data.data.list;
                    console.log(res.data.data.list);
                    console.log(res,"");
                    this.totalCount = res.data.data.total;
                    if (this.contentr.length == 0){
                        // 没有数据
                        this.noSearch = true;
                    } else {
                        // 有数据
                        this.noSearch = false;
                    }
                }else{
                    this.$Message.error(res.data.msg)
                }
            });
         
        },
        leix(newsMode,id) {
            // console.log(newsTitle,createTime,newsResource,content,accessoryUrl,newsMode,accessoryName,);
            console.log(id);
            incrReadingNum({
                id:id,
                type:1
            }).then(res=>{
                console.log(res);
            })
            if (newsMode == 1) {
                // console.log(11);
                this.$router.push({
                    name: "newsdetail",
                    params: {
                        id:id
                    },
                });
            } else if (newsMode == 2) {
                window.open(content, "_blank");
            }
        },
        //关键字变色
        brightenKeyword(val, keyword) {
            val = val + "";
            if (val.indexOf(keyword) !== -1 && keyword !== "") {
                return val.replace(
                    keyword,
                    '<font color="red">' + keyword + "</font>"
                );
            } else {
                return val;
            }
        },
    },
    mounted() {},
    filters: {
        /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy-MM-dd");
        },
    },
};
</script>
<style scoped>
.policybox {
    min-height: 100vh;
    margin-top: 60px;
    margin-left: 10px;
}
.code-row-bg {
    width: 340px;
    margin-left: 261px;
}

.zixing {
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4d4d4d;
    line-height: 21px;
    cursor: pointer;
}

.zi {
    margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304c87;
    line-height: 21px;
}

.jigoubox {
    width: 100px;
    margin-left: 448px;
    margin-top: 20px;
    /* display: flex;
    justify-content: space-around */
}

.hengxian {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 1100px;
    height: 1px;
    background-color: #005cb0;
    overflow: hidden;
    /* margin-left: 100px; */
}

.ziti {
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2c3b5b;
    line-height: 24px;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 10px;
}

.falv {
    width: 1000px;
    display: flex;
    justify-content: space-between;
    margin-left: 60px;
}

.input {
    width: 200px;
}

.btn {
    width: 90px;
    height: 34px;
    background: #2d8cf0;
    color: white;
    margin-left: 5px;
}

.contentr {
    width: 1100px;
    background: #ffffff;
}

.tit {
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #1a1a1a;
    line-height: 20px;
    cursor: pointer;
}

.con {
    width: 1100px;
    height: 25px;
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4d4d4d;
    /* line-height: 9px; */
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.xiaozi {
    width: 500px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #808080;
    line-height: 16px;
    margin-top: 20px;
}

.fgenye {
    width: 600px;
    margin-top: 500px;
    margin-left: 600px;
}
.wupipei {
    display: none;
}
.baoqian {
    width: 500px;
    margin-top: 200px;
    margin-left: 420px;
}
.fenye {
    width: 500px;
    margin-top: 50px;
    margin-left: 705px;
    margin-bottom: 20px;
}
</style>